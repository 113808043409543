<template>
  <v-container v-if="hasPermission" fluid>
    <v-row justify="center">
      <v-col cols="12">
        <mex-heading content="Product Info View" />
      </v-col>
    </v-row>
    <mex-sperm-spinner
      v-if="isLoading"
      :spinnerText="loadingText"
    />
    <v-row v-else justify="center">
      <v-col cols="4">
        <v-autocomplete
          v-model="selectedDrug"
          :items="drugNames"
          color="primaryAccent"
          dense
          hide-details
          label="Drugs"
          outlined
        >
          <template v-slot:item="data">
            <v-list-item-content>
              <v-list-item-title>{{ data.item.text }}</v-list-item-title>
              <v-list-item-subtitle class="ma-2">{{ data.item.description }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </v-autocomplete>
      </v-col>
    </v-row>
    <mex-sperm-spinner
      v-if="isLoadingPIs"
      :spinnerText="loadingText"
    />
    <template v-else>
      <v-row justify="center">
        <mex-btn v-if="selectedDrug" content="ProductInfo" icon="mdi-plus" @click="openProductInfoEditDialog(null)" />
      </v-row>
      <v-row>
        <v-col v-for="(item, idx) in productInfoIDs" :key="item.ProductInfoID" cols="3">
          <product-info
            :drug-id="selectedDrug"
            :class="item.ProductInfoID === parseInt(preselectedProductInfoID) ? 'glow-effect' : ''"
            :product-info-id="item.ProductInfoID"
            @startEditMode="openProductInfoEditDialog"
            @startDuplicateMode="openProductInfoDuplicateDialog"
          ></product-info>
        </v-col>
      </v-row>
    </template>
    <v-dialog
      v-model="showProductInfoEditOrDuplicateDialog"
      max-width="800"
      overlay-color="primary"
      overlay-opacity="0.8"
      persistent
      scrollable
    >
      <product-info
        :drug-id="selectedDrug"
        :drug-name="getSelectedDrugName"
        :product-info-id="editOrDuplicateProductInfoID"
        :unavailableDateRanges="globallyUnavailableDateRanges"
        :duplicating="duplicatingProductInfo"
        editable
        @cancelProductInfo="closeProductInfoEditOrDuplicateDialog"
        @savedProductInfo="closeInfoEditOrDuplicateDialogAndFetch"
        @deletedProductInfo="closeInfoEditOrDuplicateDialogAndFetch"
      />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import requiredPermissions from "../../requiredPermissions";
import {
  assignPropertyIfExists,
} from "../../functions/assignPropertyIfExists";
import drugsService from "../../services/drugs.service";
import productInfosService from "../../services/productInfos.service";
import ImageUpload from "../../components/LicSrvComponents/ImageUpload.vue";
import ProductInfo from "../../components/LicSrvComponents/ProductInfo.vue";

export default {
  name: "ProductInfoView",
  components: { ProductInfo, ImageUpload },
  computed: {
    ...mapGetters("sysAuth", ["getUserPermissions"]),
    getSelectedDrugName() {
      let drugNameEntry = this.drugNames.filter(x => x.value === this.selectedDrug);
      if(drugNameEntry?.length) {
        return drugNameEntry[0].text
      }
      return ""
    }
  },
  data() {
    return {
      hasPermission: false,
      isLoading: false, isLoadingPIs: false,
      showProductInfoEditOrDuplicateDialog: false,
      duplicatingProductInfo: false,
      loadingText: "",
      drugNames: [],
      selectedDrug: null,
      productInfoIDs: [],
      editOrDuplicateProductInfoID: null,
      globallyUnavailableDateRanges: [],
      preselectedProductInfoID: null,
    };
  },
  watch: {
    selectedDrug: {
      handler() {
        if (this.selectedDrug) {
          this.$store.commit("selectedProperties/setProductInfo", this.selectedDrug);
          this.fetchPIsByDrugID();
        } else {
          this.selectedDrug = null;
        }
      }
    }
  },
  async created() {
    this.$userPermissions.fetchCurrentUserPermissions(requiredPermissions.keyTEXAll, this.$store)
      .then(async (hasPermission) => {
        if (hasPermission) {
          this.hasPermission = true;
          this.selectedDrug = assignPropertyIfExists([this.$route.params, this.$route.query], "id", this.$store, "productInfo", "setProductInfo");
          if (this.selectedDrug && this.$route.query.selectedProductInfoID) {
            this.preselectedProductInfoID = this.$route.query.selectedProductInfoID;
          }
          await this.fetchDrugNames();
        } else {
          this.$router.push({ name: "NotFound" });
        }
      })
      .catch((err) => {
        this.$router.push({ name: "NotFound" });
      });
  },
  beforeRouteLeave(to, from, next) {
    if (this.editMode && !this.showSaveRequest) {
      this.showSaveRequest = true;
    } else {
      next();
    }
  },
  methods: {
    async fetchDrugNames() {
      this.isLoading = true;
      this.loadingText = "Loading drugs";
      try {
        (await drugsService.getAllDrugs()).data.forEach(drug => {
          this.drugNames.push({
            value: drug.DrugID,
            text: `${drug.product} | MED_ID ${drug.medID}`
          });
        });
      } catch (err) {
        this.$toast[err?.response.status === 404 ? "warning" : "error"](err.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },
    async getUnavailableDateRanges() {
      try {
        this.isLoading = true;
        this.loadingText = "Loading unavailable date ranges";
        let response = await productInfosService.getUnavailableDateRangesForProductInfo(this.selectedDrug, null);
        this.globallyUnavailableDateRanges = [...response.data.unavailableDateRanges];
      } catch(err) {
        this.$toast[err.response.status === 404 ? "warning" : "error"](err.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchPIsByDrugID() {
      this.isLoading = true;
      this.loadingText = "Loading ProductInfos";
      try {
        this.productInfoIDs = [];
        this.productInfoIDs = (await productInfosService.getProductInfoByDrugID(this.selectedDrug)).data;
        await this.getUnavailableDateRanges();
      } catch (err) {
        this.$toast[err.response.status === 404 ? "warning" : "error"](err.response.data.message);
      } finally {
        this.isLoading = false;
      }
    },
    async openProductInfoEditDialog(value) {
      await this.getUnavailableDateRanges();
      this.showProductInfoEditOrDuplicateDialog = true;
      this.editOrDuplicateProductInfoID = value;
    },
    async openProductInfoDuplicateDialog(value) {
      await this.getUnavailableDateRanges();
      this.duplicatingProductInfo = true;
      this.showProductInfoEditOrDuplicateDialog = true;
      this.editOrDuplicateProductInfoID = value;
    },
    closeProductInfoEditOrDuplicateDialog() {
      this.showProductInfoEditOrDuplicateDialog = false;
      this.duplicatingProductInfo = false;
      this.editOrDuplicateProductInfoID = null;
    },
    closeInfoEditOrDuplicateDialogAndFetch() {
      this.closeProductInfoEditOrDuplicateDialog();
      this.fetchPIsByDrugID();
    }
  }
};
</script>

<style>
@keyframes glow {
  0% {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0);
  }
  50% {
    box-shadow: 0 0 50px rgba(255, 255, 255, 0.75);
  }
  100% {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0);
  }
}

.glow-effect {
  animation: glow 2.5s ease-in-out 3 forwards;
}
</style>
